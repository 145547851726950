<template>
    <div id="store" :style="{ width: '100%', height: '400px' }"></div>
</template>


<script setup>
import {  onMounted,onUnmounted,onUpdated } from "vue"
import * as echarts from 'echarts';
/* eslint-disable */
// eslint-disable-next-line vue/no-setup-props-destructure
import { defineProps } from "vue"

const props = defineProps({
  dynamicProp: {
    type: Object,
    required: true
  }
})

const echart = echarts;

onMounted(() => {
  initChart();
});

onUpdated(() => {
  initChart(props.dynamicProp.statisticStores);
});

onUnmounted(() => {
  echart.dispose;
});

const initChart =(data)=>{
  const chart = echart.init(document.getElementById("store"));
  chart.setOption({
    tooltip: {trigger: 'item'},
    legend: {top: '5%',left: 'center'},
    series:[
    {
      name: '门店收入',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 22,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data,
    }
    ]
  })
  
  window.onresize = function() {chart.resize()};
  return { initChart };    
}
</script>